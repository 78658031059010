import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { LoadingBar } from "../../../common/Components/LoadingBar";
import { SiteSearchResultsModel } from "../Contracts/contracts";
import "../Style/SiteSearch.scss";
import { RenderSearchResults } from "./SearchResults";

const submitSearch = async (searchValue: string, contentId: string) => {
	const sanitisedSearchValue = searchValue.replace(/[^a-zA-Z0-9\s:]/g, "");
	const resp = await fetch(
		`/Umbraco/Api/SiteSearch/SearchSite?searchValue=${sanitisedSearchValue}&contentId=${contentId}`,
		{
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			}
		}
	);
	return resp.json();
};

export const SiteSearch = (props: { contentId: string }) => {
	const { contentId } = props;
	const [searchValue, setSearchValue] = useState<string>("");
	const [searchResults, setSearchResults] = useState<SiteSearchResultsModel | undefined>(
		undefined
	);
	const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);

	const handleSubmit = async () => {
		setSubmitDisabled(true);
		const results = await submitSearch(searchValue, contentId);
		if (results?.Results) {
			setSearchResults(results);
			if(error){
				setError(false);
			}
		} else {
			setError(true);
		}
		setSubmitDisabled(false);
	};

	return (
		<>
			<div className="site-search-image-wrapper" onClick={() => setShowModal(true)}>
				<img src="https://static.sfox.au/images/search-icon.png" alt="search" />
			</div>
			<Modal
				className="site-search-modal"
				show={showModal}
				onHide={() => setShowModal(false)}
				centered
				size="xl"
			>
				<Modal.Header>
					<Modal.Title>Search</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="site-search-wrapper">
						<input
							className="site-search-input"
							onChange={(e) => setSearchValue(e.target.value)}
							type="text"
							name="searchValue"
							placeholder="Please enter a search value"
							onKeyDown={async (e) => {
								if (
									e.key === "Enter" ||
									(e.keyCode === 13 && searchValue !== "" && contentId)
								) {
									handleSubmit();
								}
							}}
						/>
						<button
							disabled={submitDisabled}
							className="site-search-button"
							onClick={async () => {
								if (searchValue !== "" && contentId) {
									handleSubmit();
								}
							}}
						>
							Go
						</button>
					</div>
					{submitDisabled && <LoadingBar />}
					<RenderSearchResults results={searchResults} />
					{error && (
						<p className="site-search-error">
							Error: Something went wrong with your search.
						</p>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};
